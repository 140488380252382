.courses-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif; /* 통일된 폰트 사용 */
}

.courses-content {
  margin-top: 20px; /* 유튜브 멤버십 섹션과의 간격 조정 */
}

section {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.youtube-section,
.consulting-section {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.youtube-section {
  flex-direction: row;
}

.consulting-section {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content {
  flex: 1;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coaching-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

ul li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #007bff;
}

.cta-button {
  display: inline-block;
  margin-top: 10px; /* 버튼과 다른 요소 사이의 여백 추가 */
  padding: 6px 10px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px; /* 모서리 둥글게 */
  transition: background-color 0.3s, transform 0.2s; /* 호버 효과 추가 */
  font-size: 0.85rem; /* 폰트 크기 조정 */
  border: none; /* 기본 테두리 제거 */
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 호버 시 약간 커지는 효과 */
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin-bottom: 10px;
}

.emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.large-emoji {
  font-size: 100px;
  line-height: 1;
}

@media (max-width: 768px) {
  .consulting-section {
    flex-direction: column;
  }

  .image-container {
    order: -1; /* 모바일에서는 이미지를 위로 */
  }
}

.course-status {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.course-status h2 {
  margin: 0 0 10px;
}

.course-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.course-item:last-child {
  border-bottom: none;
}

.course-item h3 {
  margin: 0;
  font-size: 1.5rem;
}

.course-item p {
  margin: 5px 0;
}

.status {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin-top: 10px; /* 상태와 버튼 사이의 여백 추가 */
  margin-bottom: 10px; /* 상태 아래 여백 추가 */
}

.cta-button {
  display: inline-block;
  margin-top: 10px; /* 버튼과 다른 요소 사이의 여백 추가 */
  padding: 6px 10px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px; /* 모서리 둥글게 */
  transition: background-color 0.3s, transform 0.2s; /* 호버 효과 추가 */
  font-size: 0.85rem; /* 폰트 크기 조정 */
  border: none; /* 기본 테두리 제거 */
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 호버 시 약간 커지는 효과 */
}

.ongoing-courses {
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px; /* 진행 중인 강의 섹션 아래 여백 조정 */
}

.ongoing-courses h2 {
  margin: 0 0 10px;
}

.ongoing-courses .course-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.ongoing-courses .course-item:last-child {
  border-bottom: none;
}

.course-status,
.ongoing-courses {
  margin-bottom: 40px;
}

.course-status h2,
.ongoing-courses h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* 통일된 색상 사용 */
}

.course-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 카드 간의 간격 */
}

.course-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px); /* 카드의 너비 조정 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  margin-bottom: 20px; /* 카드 간의 여백 추가 */
}

.course-card:hover {
  transform: translateY(-5px); /* 카드 호버 효과 */
}

.course-card.upcoming {
  border-left: 5px solid #007bff; /* 모집 중인 강의 구분 */
}

.course-card.ongoing {
  border-left: 5px solid #28a745; /* 진행 중인 강의 구분 */
}

.course-card h3 {
  font-size: 1.5rem;
  margin: 0 0 10px;
}

.course-card p {
  margin: 5px 0;
}

.status {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin-top: 10px; /* 상태와 버튼 사이의 여백 추가 */
  margin-bottom: 10px; /* 상태 아래 여백 추가 */
}

.cta-button {
  display: inline-block;
  margin-top: 10px; /* 버튼과 다른 요소 사이의 여백 추가 */
  padding: 6px 10px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px; /* 모서리 둥글게 */
  transition: background-color 0.3s, transform 0.2s; /* 호버 효과 추가 */
  font-size: 0.85rem; /* 폰트 크기 조정 */
  border: none; /* 기본 테두리 제거 */
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 호버 시 약간 커지는 효과 */
}

/* 추가적인 스타일 */
.youtube-section,
.consulting-section,
.faq-section {
  margin-bottom: 40px;
}

.youtube-section h2,
.consulting-section h2,
.faq-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.faq-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.button-container {
  margin-top: 15px; /* 버튼과 다른 요소 사이의 여백 추가 */
  text-align: right; /* 버튼을 오른쪽 정렬 */
}

.cta-button {
  display: inline-block;
  padding: 8px 12px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px; /* 모서리 둥글게 */
  transition: background-color 0.3s, transform 0.2s; /* 호버 효과 추가 */
  font-size: 0.9rem; /* 폰트 크기 조정 */
  border: none; /* 기본 테두리 제거 */
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* 호버 시 약간 커지는 효과 */
}

.image-placeholder {
  width: 100%; /* 이미지의 너비를 카드에 맞춤 */
  height: 0; /* 높이를 0으로 설정 */
  padding-bottom: 75%; /* 4:3 비율로 조정 */
  background-color: #e0e0e0; /* placeholder 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* 이미지와 제목 사이의 여백 */
  position: relative; /* 자식 요소의 절대 위치를 위한 설정 */
}

.image-placeholder img {
  position: absolute; /* 절대 위치로 설정 */
  top: 50%; /* 수직 중앙 정렬 */
  left: 50%; /* 수평 중앙 정렬 */
  transform: translate(-50%, -50%); /* 중앙 정렬을 위한 변환 */
  max-width: 90%; /* 최대 너비를 90%로 설정 */
  max-height: 90%; /* 최대 높이를 90%로 설정 */
  object-fit: cover; /* 이미지 비율 유지 */
}
